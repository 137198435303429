import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const WaterSports = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 20 17'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M2.07 3.14C3.42 1.99 4.49 1.08 7.39 2.7C8.78 3.48 9.91 3.78 10.84 3.78C12.48 3.78 13.54 2.88 14.46 2.09C14.79 1.82 14.83 1.32 14.55 0.99C14.28 0.66 13.79 0.61 13.46 0.89C12.11 2.05 11.04 2.96 8.14 1.33C4.31 -0.81 2.52 0.71 1.07 1.94C0.75 2.22 0.7 2.72 0.98 3.05C1.26 3.38 1.74 3.42 2.07 3.14ZM13.46 4.81C12.11 5.97 11.04 6.88 8.14 5.25C4.31 3.11 2.52 4.63 1.07 5.86C0.75 6.14 0.7 6.64 0.98 6.97C1.26 7.3 1.74 7.34 2.07 7.06C3.42 5.91 4.49 5 7.39 6.62C8.78 7.4 9.91 7.7 10.84 7.7C12.48 7.7 13.54 6.8 14.46 6.01C14.79 5.74 14.83 5.24 14.55 4.91C14.28 4.58 13.79 4.54 13.46 4.81ZM13.46 8.73C12.11 9.89 11.04 10.8 8.14 9.17C4.31 7.03 2.52 8.56 1.07 9.78C0.75 10.06 0.7 10.56 0.98 10.89C1.26 11.22 1.74 11.26 2.07 10.98C3.42 9.83 4.49 8.92 7.39 10.55C8.78 11.33 9.91 11.62 10.84 11.62C12.48 11.62 13.54 10.72 14.46 9.93C14.79 9.66 14.83 9.16 14.55 8.83C14.28 8.5 13.79 8.46 13.46 8.73Z" />
  </StyledSvg>
);

WaterSports.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default WaterSports;
