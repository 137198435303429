import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const WorkAnniversary = ({marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'} width="33" height="36">
    <path d="M10.5062 28.4072C11.0093 29.9358 10.6076 30.958 10.1448 32.1235C9.75405 33.1073 9.32626 34.1945 9.52144 35.6048H10.7336C10.5211 34.4263 10.8704 33.5437 11.2636 32.5527C11.7143 31.4173 12.2125 30.1514 11.7554 28.4072H10.5062Z" fill="#4D68D5"/>
    <path d="M24.8277 26.0506H23.8562C23.5001 27.4067 23.8876 28.3917 24.2382 29.2743C24.5448 30.045 24.8161 30.7321 24.6507 31.6484H25.5932C25.7457 30.5513 25.4125 29.7059 25.1082 28.94C24.7492 28.0345 24.4366 27.2393 24.8277 26.0506Z" fill="#4D68D5"/>
    <path d="M22.089 10.961C22.089 5.5982 18.5431 0 11.0823 0C3.62109 0 0.0751953 5.5982 0.0751953 10.961C0.0751953 15.2442 3.44844 22.3418 10.106 25.06L9.04677 27.1654C8.89988 27.4573 9.04959 27.6943 9.38079 27.6943H12.783C13.1146 27.6943 13.2639 27.4573 13.117 27.1654L12.0574 25.06C18.715 22.3418 22.089 15.2442 22.089 10.961Z" fill="#4D68D5"/>
    <path d="M32.9251 12.4809C32.9251 8.31006 30.1663 3.95604 24.3636 3.95604C23.4517 3.95604 22.6154 4.06364 21.8532 4.26105C23.052 6.20703 23.6996 8.52408 23.6996 10.961C23.6996 13.4604 22.6902 16.5493 20.9988 19.224C20.7316 19.6469 20.4201 20.0987 20.0679 20.5639C19.9798 20.6822 19.8893 20.8021 19.7959 20.9215C20.8579 21.9533 22.1224 22.8422 23.6042 23.4475L22.7804 25.0853C22.6665 25.312 22.7828 25.4967 23.0404 25.4967H25.6864C25.9444 25.4967 26.0607 25.312 25.9464 25.0853L25.1222 23.4475C30.3004 21.3326 32.9251 15.8127 32.9251 12.4809Z" fill="#4D68D5"/>
  </StyledSvg>
);

WorkAnniversary.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default WorkAnniversary;

