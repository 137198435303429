import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

// const StyledSvg = styled.svg`
//   margin-top: ${(props) => props.marginTop};
//   float: left;
//   max-width: 25px;
//   min-width: 10px;
// `;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
  //background-color: #da6c30;
  border-radius: 4px;
  > img {
    width: 50px;
    height: 50px;
  }
`;

const WellnessEvent = () => (
  <IconContainer>
    <img src={'/public/images/CompanyDashBoardV2/NewIcon/Social-Icon.png'} />
  </IconContainer>
);

WellnessEvent.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string
};

export default WellnessEvent;
