import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const NewStepsIcon = (props) => (
  /*<StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path
      fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"}
      d="M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590,
        13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080,
        5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014,
        10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z"
    >
    </path>
  </StyledSvg>*/
  <StyledSvg width={props.width || "21"} height={props.height || "28"} viewBox="0 0 21 28" fill={props.color || "none"} xmlns="http://www.w3.org/2000/svg">
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.93602 15.4314L3.68213 16.2137L4.01664 18.4706C4.19901 19.7009 5.35251 20.5579 6.57997 20.3751L7.37038 20.2574C8.59784 20.0746 9.4529 18.9185 9.27055 17.6882L8.93602 15.4314Z" fill="#32DBC6"/>
      <path d="M8.83531 6.98846C8.58092 5.2723 6.8612 -0.0220191 3.6479 0.456473C2.65279 0.604676 2.04262 1.45286 1.70141 2.33443C1.04131 4.03998 0.996906 6.14053 1.22165 7.93682C1.373 9.14651 1.75124 10.315 2.17985 11.4512C2.29117 11.7463 3.36444 14.723 3.45917 14.709L8.71306 13.9266C8.27399 10.9645 9.08968 8.70458 8.83531 6.98846Z" fill="#32DBC6"/>
      <path d="M13.0097 17.4446C12.7927 18.6692 13.6148 19.8491 14.8366 20.0666L15.5195 20.1882C16.7413 20.4057 17.9186 19.5817 18.1356 18.3571L18.5301 16.1311L13.4042 15.2185L13.0097 17.4446Z" fill="#32DBC6"/>
      <path d="M20.9821 2.54233C20.6659 1.65151 20.1411 0.872034 19.1505 0.695719C15.952 0.12627 14.1386 5.10762 13.8359 6.8158C13.5332 8.52407 14.1696 10.9 13.6471 13.8485L18.773 14.7611C18.897 14.4961 20.1269 11.9338 20.2465 11.642C20.707 10.5183 21.1684 9.35913 21.3538 8.15424C21.6292 6.36508 21.5938 4.26593 20.9821 2.54233Z" fill="#32DBC6"/>
    </svg>

  </StyledSvg>

);

NewStepsIcon.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default NewStepsIcon;
