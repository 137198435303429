import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
  
  > tspan {
    white-space: pre;
  }
`;

const Weight = (props) => (
  <StyledSvg viewBox="0 0 18 18" width="18" height="18">
    <path fillRule="evenodd"
      fill={props.color || "rgb(153, 153, 153)"} id="icon"
      d="M13.98 0.32L3.33 0.32C1.57 0.32 0.14 1.75 0.14 3.52L0.14 14.18C0.14 15.94 1.57 17.37 3.33 17.37L13.98 17.37C15.74 17.37 17.17 15.94 17.17 14.18L17.17 3.52C17.17 1.75 15.74 0.32 13.98 0.32ZM11.93 8.19L5.38 8.19L2.76 4.91C2.76 4.91 4.72 2.28 8.65 2.28C12.58 2.28 14.55 4.91 14.55 4.91L11.93 8.19ZM10.37 7.53L11.26 3.6L8.67 7.53L10.37 7.53Z" />
  </StyledSvg>
);

Weight.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string
};

export default Weight;
