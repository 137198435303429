import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const WinterSports = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 23 26'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M17.97 13.8L17.66 12.61L15.4 13.22L13.49 12.11L15.87 11.06L15.87 9.94L13.49 8.89L15.4 7.78L17.66 8.38L17.97 7.2L16.9 6.91L18 6.27L17.39 5.21L16.29 5.85L16.57 4.77L15.39 4.46L14.79 6.72L12.89 7.82L13.17 5.25L12.2 4.68L10.11 6.22L10.11 4.01L11.76 2.36L10.9 1.49L10.11 2.27L10.11 1L8.89 1L8.89 2.27L8.1 1.49L7.24 2.36L8.89 4.01L8.89 6.22L6.8 4.68L5.83 5.25L6.11 7.82L4.21 6.72L3.61 4.46L2.42 4.77L2.71 5.85L1.61 5.21L1 6.27L2.1 6.91L1.03 7.2L1.34 8.38L3.6 7.78L5.5 8.89L3.13 9.94L3.13 11.06L5.5 12.11L3.6 13.22L1.34 12.61L1.03 13.8L2.1 14.09L1 14.73L1.61 15.79L2.71 15.15L2.42 16.23L3.61 16.54L4.21 14.28L6.11 13.18L5.83 15.75L6.8 16.31L8.89 14.78L8.89 16.99L7.24 18.64L8.1 19.51L8.89 18.73L8.89 20L10.11 20L10.11 18.73L10.9 19.51L11.76 18.64L10.11 16.99L10.11 14.78L12.2 16.32L13.17 15.75L12.89 13.18L14.79 14.28L15.39 16.54L16.57 16.23L16.29 15.15L17.39 15.79L18 14.73L16.9 14.09L17.97 13.8ZM14.1 10.5L12.18 11.35L10.72 10.5L12.18 9.65L14.1 10.5ZM11.8 6.5L11.57 8.58L10.11 9.44L10.11 7.74L11.8 6.5ZM7.2 6.5L8.89 7.74L8.89 9.44L7.42 8.58L7.2 6.5ZM4.9 10.5L6.82 9.65L8.28 10.5L6.81 11.35L4.9 10.5ZM7.2 14.5L7.42 12.42L8.89 11.56L8.89 13.26L7.2 14.5ZM11.8 14.5L10.11 13.26L10.11 11.56L11.57 12.42L11.8 14.5Z" />
  </StyledSvg>
);

WinterSports.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default WinterSports;
