import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;

  > tspan {
    white-space: pre;
  }
`;

const SleepAverage = ({color}) => (
  <StyledSvg viewBox="0 0 26 19" width="26" height="19">
    <path fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"} id="icon"
      d="M7.32,10.06c1.92,0 3.48,-1.56 3.48,-3.49c0,-1.92 -1.56,-3.49 -3.48,-3.49c-1.92,0 -3.48,1.56 -3.48,3.49c0,1.92 1.56,3.49 3.48,3.49zM21.23,3.08h-9.27v8.14h-9.27v-10.46h-2.32v17.44h2.32v-3.49h20.86v3.49h2.32v-10.46c0,-2.57 -2.07,-4.65 -4.64,-4.65z"/>
  </StyledSvg>
);

SleepAverage.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
};

export default SleepAverage;
