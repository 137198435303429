import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const NextArrow = ({color, marginTop}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M17,8.96c0,-0.15 -0.06,-0.28 -0.17,-0.39l-4.57,-4.48c-0.11,-0.11 -0.25,-0.17 -0.4,-0.17c-0.15,0 -0.29,0.06 -0.4,0.17c-0.11,0.11 -0.17,0.24 -0.17,0.39v2.24h-2c-0.58,0 -1.11,-0.02 -1.57,-0.05c-0.46,-0.03 -0.92,-0.1 -1.37,-0.19c-0.46,-0.09 -0.85,-0.21 -1.19,-0.37c-0.34,-0.16 -0.65,-0.36 -0.94,-0.61c-0.29,-0.25 -0.53,-0.54 -0.71,-0.88c-0.18,-0.34 -0.33,-0.74 -0.43,-1.21c-0.1,-0.47 -0.16,-0.99 -0.16,-1.58c0,-0.32 0.01,-0.68 0.04,-1.08c0,-0.04 0.01,-0.1 0.02,-0.21c0.01,-0.1 0.02,-0.18 0.02,-0.23c0,-0.09 -0.03,-0.16 -0.08,-0.22c-0.05,-0.06 -0.12,-0.09 -0.21,-0.09c-0.1,0 -0.18,0.05 -0.25,0.15c-0.04,0.05 -0.08,0.12 -0.12,0.19c-0.04,0.08 -0.08,0.16 -0.12,0.26c-0.04,0.1 -0.08,0.17 -0.09,0.21c-0.76,1.66 -1.13,2.98 -1.13,3.95c0,1.16 0.16,2.13 0.47,2.91c0.96,2.35 3.57,3.53 7.81,3.53h2v2.24c0,0.15 0.06,0.28 0.17,0.39c0.11,0.11 0.25,0.17 0.4,0.17c0.15,0 0.29,-0.06 0.4,-0.17l4.57,-4.48c0.11,-0.11 0.17,-0.24 0.17,-0.39z" />
  </StyledSvg>
);

NextArrow.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
};

export default NextArrow;
