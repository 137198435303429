import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const ResistanceTraining = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 27 21'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M25.41 10.82L25.41 8.91L27.35 8.91C27.7 8.91 27.99 8.63 27.99 8.28L27.99 5.73C27.99 5.38 27.7 5.1 27.35 5.1L25.41 5.1L25.41 3.19C25.41 2.14 24.54 1.28 23.47 1.28L22.72 1.28C22.45 0.55 21.74 0.01 20.89 0.01L19.6 0.01C19.24 0.01 18.95 0.29 18.95 0.65L18.95 5.1L10.04 5.09L10.04 0.64C10.04 0.29 9.75 0.01 9.4 0.01L8.11 0.01C7.26 0.01 6.55 0.54 6.28 1.28L5.52 1.28C4.45 1.28 3.58 2.13 3.58 3.18L3.58 5.09L1.64 5.09C1.29 5.09 1 5.37 1 5.72L1 8.27C1 8.62 1.28 8.9 1.64 8.9L3.58 8.9L3.58 10.81C3.58 11.86 4.45 12.72 5.52 12.72L6.27 12.72C6.54 13.45 7.25 13.99 8.1 13.99L9.39 13.99C9.75 13.99 10.04 13.71 10.04 13.36L10.04 8.91L18.95 8.91L18.95 13.36C18.95 13.71 19.23 13.99 19.59 13.99L20.88 13.99C21.73 13.99 22.44 13.46 22.71 12.72L23.47 12.72C24.54 12.72 25.41 11.87 25.41 10.82ZM26.7 6.37L26.7 7.64L25.41 7.64L25.41 6.37L26.7 6.37ZM2.29 7.63L2.29 6.36L3.58 6.36L3.58 7.63L2.29 7.63ZM5.52 11.45C5.16 11.45 4.87 11.16 4.87 10.81L4.87 3.18C4.87 2.83 5.16 2.55 5.52 2.55L6.17 2.55L6.16 11.45L5.52 11.45ZM8.75 12.72L8.1 12.72C7.74 12.72 7.45 12.43 7.46 12.08L7.46 1.91C7.46 1.56 7.75 1.28 8.11 1.28L8.75 1.28C8.75 1.67 8.75 12.52 8.75 12.72ZM10.04 7.63L10.04 6.36L18.95 6.37L18.95 7.64L10.04 7.63ZM20.88 12.72L20.24 12.72L20.24 5.73L20.24 5.73L20.24 5.73L20.24 1.28L20.89 1.28C21.25 1.28 21.53 1.57 21.53 1.92L21.53 12.09C21.53 12.44 21.24 12.72 20.88 12.72ZM23.47 11.45L22.82 11.45L22.83 2.55L23.47 2.55C23.83 2.55 24.12 2.84 24.12 3.19L24.12 10.82C24.12 11.17 23.83 11.45 23.47 11.45Z" />
  </StyledSvg>
);

ResistanceTraining.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default ResistanceTraining;
