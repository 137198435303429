import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const WheelSports = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 23 26'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M10 1C5.04 1 1 5.04 1 10C1 14.96 5.04 19 10 19C14.96 19 19 14.96 19 10C19 5.04 14.96 1 10 1ZM4.98 5.51L8.99 9.52C8.97 9.55 8.96 9.59 8.94 9.62L3.27 9.62C3.36 8.05 3.99 6.61 4.98 5.51ZM3.27 10.37L8.94 10.37C8.96 10.41 8.97 10.45 8.99 10.48L4.98 14.49C3.99 13.39 3.36 11.95 3.27 10.37ZM9.62 16.73C8.05 16.64 6.61 16.01 5.51 15.02L9.52 11.01C9.55 11.03 9.59 11.04 9.62 11.06L9.62 16.73L9.62 16.73ZM9.62 8.94C9.59 8.96 9.55 8.97 9.52 8.99L5.51 4.98C6.61 3.99 8.05 3.36 9.62 3.27L9.62 8.94ZM16.73 9.62L11.06 9.62C11.04 9.59 11.03 9.55 11.01 9.52L15.02 5.51C16.01 6.61 16.64 8.05 16.73 9.62ZM10.37 3.27C11.95 3.36 13.39 3.99 14.49 4.98L10.48 8.99C10.45 8.97 10.41 8.96 10.37 8.94L10.37 3.27ZM10.37 16.73L10.37 11.06C10.41 11.04 10.45 11.03 10.48 11.01L14.49 15.02C13.39 16.01 11.95 16.64 10.37 16.73ZM15.02 14.49L11.01 10.48C11.03 10.45 11.04 10.41 11.06 10.37L16.73 10.37C16.64 11.95 16.01 13.39 15.02 14.49Z" />
  </StyledSvg>
);

WheelSports.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default WheelSports;
