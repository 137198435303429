import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
  
  > tspan {
    white-space: pre;
  }
`;

const WaterIntake = ({color}) => (
  <StyledSvg viewBox="0 0 15 21" width="15" height="21">
    <path fillRule="evenodd"
      fill={color || "rgb(153, 153, 153)"} id="icon"
      d="M1.22 0.4C0.92 0.4 0.62 0.53 0.42 0.76C0.21 1 0.11 1.31 0.14 1.63L1.9 19.05L2.13 19.26C2.35 19.46 3.63 20.46 7.49 20.46C11.35 20.46 12.63 19.47 12.85 19.26L13.09 19.05L14.84 1.63C14.87 1.31 14.77 1 14.57 0.76C14.36 0.53 14.07 0.39 13.76 0.39L1.22 0.39L1.22 0.4ZM11.56 18.15C11.09 18.37 9.9 18.8 7.49 18.8C5.07 18.8 3.88 18.37 3.43 18.15L1.93 3.27C1.9 2.96 2 2.64 2.21 2.41C2.41 2.17 2.7 2.04 3.01 2.04L11.97 2.04C12.28 2.04 12.57 2.17 12.78 2.41C12.98 2.64 13.08 2.96 13.05 3.27L11.56 18.15ZM3.98 7.24L4.86 16.88C4.86 16.88 5.79 17.4 7.33 17.4C8.88 17.4 10.28 16.88 10.28 16.88L11.12 7.69C10.83 7.71 10.53 7.73 10.24 7.72C8.35 7.68 6.04 7.14 3.98 7.24Z" />
  </StyledSvg>
);

WaterIntake.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string
};

export default WaterIntake;
