import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const Steps = (props) => (
  /*<StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}  width="25" height="25">
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M18.644,12.150 C18.581,12.451 18.374,12.733 17.995,12.643 C16.628,12.319 14.486,12.223 13.218,12.529 C12.752,12.643 12.535,12.440 12.360,11.882 C11.924,10.501 11.235,8.836 11.383,6.702 C11.714,1.922 13.692,0.598 15.182,0.684 C16.672,0.770 20.502,3.135 18.644,12.150 ZM5.967,12.530 C4.699,12.223 2.557,12.319 1.191,12.643 C0.811,12.733 0.604,12.451 0.542,12.150 C-1.316,3.135 2.514,0.771 4.003,0.685 C5.493,0.600 7.471,1.923 7.803,6.703 C7.951,8.837 7.262,10.503 6.826,11.884 C6.650,12.438 6.434,12.642 5.967,12.530 ZM7.088,17.183 C7.337,19.418 6.855,20.967 4.669,21.282 C2.824,21.547 1.640,20.163 1.392,17.929 C1.244,16.579 1.230,15.717 1.230,15.717 L6.671,15.003 C6.671,15.003 6.938,15.831 7.088,17.183 ZM12.513,15.003 L17.954,15.717 C17.954,15.717 17.941,16.579 17.792,17.929 C17.546,20.163 16.362,21.547 14.516,21.282 C12.330,20.967 11.847,19.418 12.097,17.183 C12.246,15.831 12.513,15.003 12.513,15.003 Z">
    </path>
  </StyledSvg>*/
  <StyledSvg width={props.width || "23"} height={props.height || "22"} marginTop={props} viewBox="0 0 23 22" fill={props.color || "none"}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93602 15.4314L3.68213 16.2137L4.01664 18.4706C4.19901 19.7009 5.35251 20.5579 6.57997 20.3751L7.37038 20.2574C8.59784 20.0746 9.4529 18.9185 9.27055 17.6882L8.93602 15.4314Z"
      fill={props.color || "#32DBC6"}/>
    <path
      d="M8.83531 6.98846C8.58092 5.2723 6.8612 -0.0220191 3.6479 0.456473C2.65279 0.604676 2.04262 1.45286 1.70141 2.33443C1.04131 4.03998 0.996906 6.14053 1.22165 7.93682C1.373 9.14651 1.75124 10.315 2.17985 11.4512C2.29117 11.7463 3.36444 14.723 3.45917 14.709L8.71306 13.9266C8.27399 10.9645 9.08968 8.70458 8.83531 6.98846Z"
      fill={props.color || "#32DBC6"}/>
    <path
      d="M13.0097 17.4446C12.7927 18.6692 13.6148 19.8491 14.8366 20.0666L15.5195 20.1882C16.7413 20.4057 17.9186 19.5817 18.1356 18.3571L18.5301 16.1311L13.4042 15.2185L13.0097 17.4446Z"
      fill={props.color || "#32DBC6"}/>
    <path
      d="M20.9821 2.54233C20.6659 1.65151 20.1411 0.872034 19.1505 0.695719C15.952 0.12627 14.1386 5.10762 13.8359 6.8158C13.5332 8.52407 14.1696 10.9 13.6471 13.8485L18.773 14.7611C18.897 14.4961 20.1269 11.9338 20.2465 11.642C20.707 10.5183 21.1684 9.35913 21.3538 8.15424C21.6292 6.36508 21.5938 4.26593 20.9821 2.54233Z"
      fill={props.color || "#32DBC6"}/>
  </StyledSvg>
)

Steps.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default Steps;
