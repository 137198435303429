import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const JobWellDone = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M17.38 11C17.79 10.5 18 9.92 18 9.25C18 8.64 17.78 8.11 17.36 7.67C16.93 7.22 16.42 7 15.84 7L12.72 7C12.75 6.89 12.78 6.8 12.81 6.72C12.84 6.64 12.89 6.55 12.94 6.46C12.99 6.37 13.03 6.3 13.05 6.25C13.18 5.98 13.29 5.76 13.36 5.59C13.43 5.42 13.5 5.19 13.57 4.89C13.64 4.59 13.68 4.3 13.68 4C13.68 3.81 13.68 3.66 13.67 3.54C13.67 3.42 13.65 3.25 13.62 3.01C13.58 2.78 13.54 2.58 13.48 2.43C13.43 2.27 13.34 2.1 13.21 1.9C13.09 1.71 12.94 1.55 12.76 1.43C12.59 1.31 12.36 1.2 12.09 1.12C11.81 1.04 11.51 1 11.16 1C10.96 1 10.8 1.07 10.65 1.22C10.5 1.38 10.38 1.57 10.27 1.81C10.17 2.04 10.09 2.24 10.05 2.42C10.01 2.59 9.96 2.83 9.91 3.13C9.84 3.46 9.79 3.7 9.76 3.84C9.73 3.99 9.66 4.17 9.56 4.41C9.46 4.64 9.35 4.83 9.21 4.97C8.97 5.23 8.59 5.7 8.08 6.38C7.71 6.88 7.33 7.35 6.94 7.8C6.55 8.24 6.27 8.47 6.09 8.49C5.9 8.5 5.74 8.58 5.6 8.73C5.47 8.87 5.4 9.04 5.4 9.24L5.4 16.75C5.4 16.95 5.47 17.13 5.61 17.27C5.76 17.42 5.93 17.49 6.12 17.5C6.38 17.51 6.97 17.68 7.9 18.02C8.47 18.22 8.93 18.37 9.25 18.48C9.58 18.58 10.03 18.7 10.62 18.82C11.2 18.94 11.74 19 12.24 19L12.43 19L13.29 19L13.69 19C14.69 18.99 15.43 18.68 15.91 18.09C16.34 17.55 16.52 16.84 16.46 15.97C16.75 15.68 16.95 15.31 17.06 14.86C17.19 14.39 17.19 13.93 17.06 13.49C17.41 13.02 17.57 12.48 17.55 11.89C17.55 11.64 17.49 11.34 17.38 11ZM3.96 8.5L0.72 8.5C0.53 8.5 0.36 8.57 0.21 8.72C0.07 8.87 0 9.05 0 9.25L0 16.75C0 16.95 0.07 17.13 0.21 17.28C0.36 17.43 0.53 17.5 0.72 17.5L3.96 17.5C4.16 17.5 4.32 17.43 4.47 17.28C4.61 17.13 4.68 16.95 4.68 16.75L4.68 9.25C4.68 9.05 4.61 8.87 4.47 8.72C4.32 8.57 4.16 8.5 3.96 8.5ZM2.67 15.78C2.52 15.93 2.36 16 2.16 16C1.96 16 1.79 15.93 1.65 15.78C1.51 15.64 1.44 15.46 1.44 15.25C1.44 15.05 1.51 14.87 1.65 14.72C1.79 14.58 1.96 14.5 2.16 14.5C2.36 14.5 2.52 14.58 2.67 14.72C2.81 14.87 2.88 15.05 2.88 15.25C2.88 15.46 2.81 15.64 2.67 15.78Z" />
  </StyledSvg>
);

JobWellDone.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string,
};

export default JobWellDone;
