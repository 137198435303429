import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSvg = styled.svg`
  margin-top: ${(props) => props.marginTop};
  float: left;
  max-width: 25px;
  min-width: 10px;
`;

const WellnessExpert = ({color, marginTop, viewBox}) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="8%" height="0.306in" marginTop={marginTop || '3px'} viewBox={viewBox || '0 0 25 25'}>
    <path fillRule="evenodd" fill={color || "rgb(153, 153, 153)"}
      d="M11.53,1.14c-0.26,-0.22 -0.63,-0.22 -0.89,0c-0.56,0.49 -3.36,3.1 -3.36,6.34c0,3.24 2.8,5.85 3.36,6.34c0.13,0.11 0.29,0.17 0.45,0.17c0.16,0 0.32,-0.06 0.44,-0.17c0.56,-0.49 3.36,-3.1 3.36,-6.34c0,-3.24 -2.8,-5.85 -3.36,-6.34zM20.67,9.34c-0.16,-0.1 -0.56,-0.32 -1.09,-0.53c-0.17,-0.07 -0.35,0.04 -0.37,0.22c-0.16,1.24 -0.59,2.74 -1.66,3.83c-0.05,0.05 -0.1,0.1 -0.15,0.14c-0.22,0.2 -0.01,0.57 0.27,0.48c0.57,-0.19 1.06,-0.42 1.49,-0.7c1.45,-0.94 1.69,-2.12 1.78,-2.56c0.01,-0.03 0.01,-0.06 0.02,-0.08c0.1,-0.31 -0.02,-0.64 -0.29,-0.8zM18.58,6.33c-0.03,-0.34 -0.29,-0.6 -0.62,-0.63c-0.3,-0.02 -1.05,-0.06 -1.97,0.05c-0.17,0.02 -0.27,0.19 -0.23,0.35c0.11,0.41 0.17,0.84 0.17,1.3c0,2.56 -1.46,4.7 -2.65,6.03c-0.18,0.2 -0.01,0.52 0.25,0.47c1.11,-0.21 2.37,-0.66 3.32,-1.63c1.93,-1.98 1.78,-5.3 1.73,-5.95zM4.59,13.01c-0.05,-0.05 -0.1,-0.09 -0.15,-0.14c-1.07,-1.09 -1.5,-2.59 -1.66,-3.83c-0.02,-0.18 -0.21,-0.29 -0.37,-0.22c-0.53,0.21 -0.93,0.43 -1.09,0.53c-0.28,0.16 -0.4,0.5 -0.29,0.8c0,0.02 0.01,0.05 0.02,0.08c0.09,0.44 0.33,1.62 1.78,2.56c0.43,0.28 0.92,0.51 1.49,0.7c0.28,0.09 0.49,-0.27 0.27,-0.48zM8.71,13.45c-1.2,-1.33 -2.65,-3.47 -2.65,-6.03c0,-0.46 0.06,-0.9 0.17,-1.3c0.04,-0.17 -0.06,-0.33 -0.23,-0.35c-0.91,-0.12 -1.66,-0.08 -1.97,-0.05c-0.33,0.03 -0.59,0.29 -0.62,0.63c-0.05,0.65 -0.2,3.97 1.73,5.95c0.94,0.97 2.2,1.42 3.32,1.63c0.26,0.05 0.43,-0.27 0.25,-0.47z" />
  </StyledSvg>
);

WellnessExpert.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  viewBox: PropTypes.string
};

export default WellnessExpert;
